.trend-c{
    /* border: 1px solid red; */
    max-width: 1000px;
   margin: 0 auto;
    
}

.cards:hover{
    box-shadow:  1px 1px 4px #aaaaaa,
             -1px -1px 4px #4e4b4b;
}
.trend-c h5{
    margin:0;
    padding: 10px;
    
}

.cards{
    border-radius: 8px;
    width: 240px;
    min-width: 200px;
    height: 180px;
    background-position:center;
    background-repeat: no-repeat;
    background-color: rgb(216, 228, 228);
    background-size: cover;
    border: 1px solid black;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
} 

.images{
    display: flex;
    gap:10px;
    justify-content: space-around;
 }

a{
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;
}
a>*{
    margin: 0;
    padding: 5px;
}
 
 @media screen and (max-width: 500px) {
    
    .cards:nth-of-type(n+2){
        display: none;
    }
    
    .cards{
        width: 320px;
        height: 180px;
       /* border: 1px solid red; */
      
        
    }
  } 

  @media screen and (min-width: 500px) and (max-width: 700px){
    
    
    .cards{
        flex-grow: 1;
        
    }
  }

   @media screen and (min-width: 700px) and (max-width: 900px){
    
   

    .cards:nth-of-type(n+4){
        display: none;
    } 
     
  } 

  .cards h5{
    background-color: rgba(245, 234, 234, 0.4);
    color :lightsteelblue;
    border-radius: 10px;
    width: 90%;
    height: auto;
    margin:  0 auto;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }