body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:lightsteelblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* start */


.layout{
  width: 100%;
}



/* navbar */

nav{
  width: 100%;
  height: 40px;
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  align-items: center;
  position: fixed;
  background-color:whitesmoke;
  z-index: 100;
}
nav input{
  border-radius: 10px;
  width:90%;
}

.user{
  text-align: end;
  padding-right: 10px;
}
nav i:hover{
transform: scale(1.1);
}
nav div{
  
  color: black;
}
nav i{
  transition: 0.2s;
  cursor: pointer;
}

.searchbar input:hover{
  border: 2px solid lightsteelblue;

}
.menu , .popular{
  display: none;
 
}

/* main */

main{
   
}

 @media screen and (max-width: 1340px) {
 nav{ 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr 1fr;
}
  .menu, .popular{
    display: inline-block;
  }

  .topics{
    display: none;
  }
  .sections{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    /* border: 1px solid red; */
   
  }
 
}

.mock{
  width:1000px;
 height: 700px;
  background-color: red;
}

.logo{
  margin-left: 5px;
}

.topics a:hover{
 background-color: lightsteelblue;
 border-radius: 5px;
 border-left: 2px solid blue;
}

.activ{
  background-color: lightsteelblue;
  border-radius: 5px;
  border-left: 2px solid blue;
}

