.filter{
    /* border: 1px solid red; */
    margin: 10px auto;
   max-width: 1000px;
   padding: 5px;
   
   border-radius: 5px;
   
}
h5{
    position: relative;
    margin:  0 auto 15px auto;
    padding-left: 5px;
}
.fcard{
    width: 100%;
    height:6vh;
    /* border: 1px solid green; */
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: whitesmoke;
}

.fcard div{
    cursor: pointer;
}
div i{
    padding-right: 5px;
}
.param:hover{
    
    color: darkblue;
    cursor: pointer;
    background-color: lightslategrey;
    /* border: 0.5px solid black; */
}
.param{
    transition: 0.2s;
    color: grey;
    
    padding: 2px 10px;
    border-radius: 20px;
}

.actives {
   color: darkblue;
   
}