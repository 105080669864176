.wrapper{
    /* border: 1px solid red; */
    max-width: 90%;
    margin:15px auto;
    border-radius: 5px;
    background-color: #d8e4e4;
    display: block;
    padding-bottom: 5px;
    
    
}
.wrapper:hover{
    

    box-shadow:  1px 1px 4px #aaaaaa,
             -1px -1px 4px #4e4b4b;
}
.post-tile{
   
    margin: 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 3px;
}
h4{
    margin: 15px;
    
    
}
.pic{
    max-width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    /* border: 1px solid green; */
    padding-bottom: 1vh;
    /* border-bottom: 1px solid grey; */
}
img{
    max-width: 95%;
    height: auto;
    border-radius: 5px;
    margin: 0 auto;   
}

.rating{
    /* border: 1px solid magenta; */
    padding-top:10px ;
    width: 50px;
    height:auto;
    margin: 0 2px;
    display: flex;
    flex-direction: column; 
    
}
.rating > *{
    margin: 0 auto;
    padding: 2px;
    margin-top: 3px;
    transition: 0.2s;
}
.rating i:hover{
    transform: scale(1.1);
    background-color: lightgrey;
    padding: 2px;
    border-radius: 3px;
    cursor: pointer;
}
.aux{
    /* border: 1px solid magenta; */
     width: 95%;
     height: auto;
     margin: 15px auto 0 auto;
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-top: 1px solid lightgrey;
}
.aux > *{
    
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    transition: 0.2s;
}
.aux i:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.rating h6{
    width: 25px;
   
    margin: 5px;
    text-align: center;
    
}


